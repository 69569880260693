footer {
  background-color: #000;
  background-color: #000;
  position: relative;
  
  .footer__container {
    width: min(1440px, 100%);
    margin: 0 auto;
    font-size: 14px;
    font-family: dm_sansregular;
    -webkit-font-smoothing: antialiased;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 64px 20px;

    @include media-breakpoint-up(md) {
      padding: 64px 40px;
    }
  
    @include media-breakpoint-up(lg) {
      padding: 64px 80px;
    }
  
    @include media-breakpoint-up(xl) {
      padding: 64px 128px;
    }
  
    @include media-breakpoint-down(xxs) {
      padding: 64px 15px;
    }
  
    @include media-breakpoint-down(xxxs) {
      padding: 64px 10px;
    }
  
    @include media-breakpoint-down(xxxxs) {
      padding: 64px 5px;
    }

    .footer-other {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .email {
        line-height: 2.5;
        padding: 0 0 5px 0;
      }
    }

    .footer-address {
      line-height: 1.3;
    }

    a {
      display: block;
      color: inherit;

      &:hover {
        color: inherit
      }
    }
  }
}