.cb-hero {
  $cb: &;
    
  width: min(1440px, 100%);
  padding: 0 20px;
  margin: 120px auto 0;
  // height: 70vh;

  @include media-breakpoint-up(md) {
    height: auto;
    margin: 40px auto 0;
    padding: 0 40px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 80px;
  }

  @include media-breakpoint-up(xl) {
    padding: 20px calc(48px + 40px);
    // height: 70vh;
  }

  @include media-breakpoint-up(xxl) {
    padding: 20px calc(48px + 80px); // 48px = 3rem + padding
  }

  @include media-breakpoint-down(xxs) {
    padding: 0 15px;
  }

  @include media-breakpoint-down(xxxs) {
    padding: 0 10px;
  }

  @include media-breakpoint-down(xxxxs) {
    padding: 0 5px;
  }

  @media (max-height: 500px) {
    height: auto;
    margin: 120px auto 0;
  }

  #{$cb}__container {
    #{$cb}__headline {
      margin-bottom: 35px;

      h2 {
        opacity: 0;
        transform: translateY(3rem);
        transition: transform 0.6s, opacity 0.9s;
        @include delay(transition, 4, 0.25s);
        @include clamp("font-size",45px,10vw,90px);
        line-height: 1;
        letter-spacing: -2px;
        font-family: Prophet;
        font-weight: 400;
        margin: 0;

        @include media-breakpoint-down(xxs) {
          font-size: 35px;
          line-height: 1.1;
        }
      
        @include media-breakpoint-down(xxxs) {
          font-size: 30px;
          line-height: 1.1;
        }
      
        @include media-breakpoint-down(xxxxs) {
          font-size: 25px;
          line-height: 1.1;
        }
      }

      img {
        @include clamp("width",60px,15vw,150px);
        margin-top: -5px;

        @include media-breakpoint-up(md) {
          margin-top: -15px;
        }

        @include media-breakpoint-down(xxs) {
          width: 50px;
        }
      
        @include media-breakpoint-down(xxxs) {
          width: 40px;
        }
      
        @include media-breakpoint-down(xxxxs) {
          width: 30px;
        }
      }
    }

    p {
      @include clamp("font-size",16px,5vw,24px);
      line-height: 1.35;
      max-width: 90%;
      -webkit-font-smoothing: antialiased;
      font-family: dm_sansregular;
      margin-bottom: 48px;
      padding-right: 10px;
      display: block;
      opacity: 0;
      transform: translateY(3rem);
      transition: transform 0.6s, opacity 0.9s;
      transition-delay: 1.3s;

      @include media-breakpoint-up(sm) {
        max-width: 75%;
      }

      @include media-breakpoint-up(md) {
        max-width: 540px;
      }
    }
  }

  .cta__container {
    margin-top: 30px;
    .cta {
      opacity: 0;
      transform: translateY(3rem);
      transition: transform 0.6s, opacity 0.9s;
      transition-delay: 1.6s;

      &:nth-child(2) {
        transition-delay: 1.8s;
      }
    }
  }

  &.animate {
    h2,
    .cta__container .cta,
    p {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
}