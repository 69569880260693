.square-bg {
  width: 45%;
  height: 65vh;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: -100;
  background-color: #f4f0ec;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 1s, opacity 1s;
  transition-delay: 2.2s;

  @include media-breakpoint-up(md) {
    width: 31%;
  }
  
  @include media-breakpoint-up(lg) {
    width: 36%;
  }

  @include media-breakpoint-up(xl) {
    width: 50%;
  }

  &.animate {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
}