header {
  width: min(1440px, 100%);
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 200;

  @include media-breakpoint-down(sm) {
    top: 0;
    position: fixed;
  }

  @include media-breakpoint-up(md) {
    padding: 0 40px;
  }
  
  @include media-breakpoint-up(lg) {
    padding: 0 calc(48px + 40px);
  }
  
  @include media-breakpoint-up(xl) {
    padding: 0 calc(48px + 40px); // 48px = 3rem + padding
  }

  @include media-breakpoint-up(xxl) {
    padding: 0 calc(48px + 80px); // 48px = 3rem + padding
  }

  @include media-breakpoint-down(xxs) {
    padding: 0 15px;
  }

  @include media-breakpoint-down(xxxs) {
    padding: 0 10px;
  }

  @include media-breakpoint-down(xxxxs) {
    padding: 0 5px;
  }

  h1 {
    flex-grow: 1;
    height: 100%;
    margin: 0;
    z-index: 1;

    .logo {
      width: 95px;
      height: 95px;
      opacity: 0;
      transform: translateY(-3rem);
      transition: transform 0.4s, opacity 0.6s;

      @include media-breakpoint-down(xxs) {
        width: 80px;
        height: 80px;
      }
    
      @include media-breakpoint-down(xxxs) {
        width: 70px;
        height: 70px;
      }
    
      @include media-breakpoint-down(xxxxs) {
        width: 60px;
        height: 60px;
      }

      &.animate {
        opacity: 1 !important;
        transform: translateY(0) !important;
      }
    }

    span {
      display: block;
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }

  .circle {
    opacity: 0;
    animation: fadeIn 2s ease 1s 1 forwards;
    @include media-breakpoint-down(sm) {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 40px;
      right: 20px;
      transition: 75ms ease-in-out;
      background-color: #000;
      border-radius: 100%;
      transform: scale(1);

      &.animate {
        transform: scale(50);
        transform-origin: center center;
      }

      @include media-breakpoint-down(xxs) {
        top: 38px;
        right: 18px;
      }
    
      @include media-breakpoint-down(xxxs) {
        top: 26px;
        right: 14px;
      }
    
      @include media-breakpoint-down(xxxxs) {
        top: 22px;
        right: 10px;
      }
    }
  }

  .hamburger {
    text-align: right;
    background-color: transparent;
    display: block;
    border: none;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 32px;
    right: 20px;
    padding: 0;
    z-index: 2;

    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-down(xxs) {
      top: 28px;
      right: 18px;
    }
  
    @include media-breakpoint-down(xxxs) {
      top: 18px;
      right: 16px;
    }
  
    @include media-breakpoint-down(xxxxs) {
      top: 14px;
      right: 12px;
    }

    &.clicked {
      fill: #fff;
      width: 32px;
      height: 32px;

      svg {
        visibility: visible;
      }
    }

    svg {
      width: 16px;
      height: 16px;
      display: block;
      margin-left: auto;
      visibility: hidden;
    }
  }

  nav {
    display: none;
    flex-direction: row;
    justify-content: center;
    position: static;
    top: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    box-shadow: none;
    width: auto;
    transition: none;
    position: relative;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    &.navShow {
      display: block;
      position: absolute;
      width: 270px;
      right: 0;
      top: 150px;

      @include media-breakpoint-down(xxs) {
        right: -25px;
      }
    
      @include media-breakpoint-down(xxxs) {
        right: -80px;
      }
    
      @include media-breakpoint-down(xxxxs) {
        right: -120px;
      }

      @media (max-height: 500px) {
        top: 120px;
      }

      a {
        &:focus {
          animation: outlineColorInverted 4s infinite;
        }
      }
    }

    &.animate {
      a {
        opacity: 1 !important;
        transform: translateY(0) !important;
      }
    }

    a {
      display: inline-block;
      font-family: Prophet;
      font-size: 32px;
      line-height: 40px;
      
      margin-bottom: 44px;
      padding: 0 20px;
      text-decoration: none;
      color: #fff;
      opacity: 0;
      transform: translateY(-3rem);
      transition: transform 0.4s, opacity 0.6s;
      @include delay(transition, 2, 0.05s);

      &:hover {
        color: #fff;
        text-decoration: underline;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        font-family: dm_sansregular;
        font-size: 16px;
        color: #000;

        &:hover {
          color: #000;
        }
      }

      @include media-breakpoint-down(xxs) {
        font-size: 30px;
        line-height: 38px;
      }
    
      @include media-breakpoint-down(xxxs) {
        font-size: 20px;
        line-height: 28px;
      }
    
      @include media-breakpoint-down(xxxxs) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}