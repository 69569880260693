
$grid-breakpoints: (
  xx: 0,
  xxxxs: 125px,
  xxxs: 175px,
  xxs: 275px,
  xs: 375px,
  xs-sm: 425px,
  sm: 576px,
  md: 768px,
  lg: 900px,
  xl: 1025px,
  xxl: 1200px,
  xxxl: 1400px,
  xxxxl: 1526px
);

// ! Clamp Mixin
// Allows you to use Clamp CSS and auto generates fallback code
// To use:
// @include clamp("font-size",20px,6vw,60px);
@mixin clamp($property, $min-size, $scaler, $max-size, $min-size-left:false, $scaler-left:false, $max-size-left:false){
  @if $min-size-left == false {
    #{$property}:$max-size; 
    #{$property}:#{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"}; 
    #{$property}:clamp($min-size, $scaler, $max-size);
  } @else if $min-size-left == 0 or $min-size-left == auto{
    #{$property}:$max-size $min-size-left; 
    #{$property}:#{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"} $min-size-left;
    #{$property}:clamp($min-size, $scaler, $max-size) $min-size-left;
  } @else {
    #{$property}:$max-size $min-size-left; 
    #{$property}:#{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"} #{"min(max(#{$min-size-left}, #{$scaler-left}), #{$max-size-left})"}; 
    #{$property}:clamp($min-size-left, $scaler-left, $max-size-left);
  }
}

@mixin delay($rule, $number, $value) {
  @for $i from 1 to ($number + 1) {
    &:nth-child(#{$i}) {
      -webkit-#{$rule}-delay: (#{$i * $value});
      #{$rule}-delay: (#{$i * $value});
    }
  }
}