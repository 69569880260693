@font-face {
  font-family: "dm_sansmedium";
  src: url("./dmsans-medium-webfont.woff2") format("woff2"),
    url("./dmsans-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dm_sansregular";
  src: url("./dmsans-regular-webfont.woff2") format("woff2"),
    url("./dmsans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Prophet";
  src: url("./Prophet-Regular.woff2") format("woff2"),
    url("./Prophet-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
