* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: "dm_sansregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  background-color: #ffffff;
  -webkit-text-size-adjust: 100%;
}

a {
  &:hover {
    text-decoration: none;
  }
}

h2,h3,h4 {
  margin: 0;
}

.reveal-item {
  opacity: 0;
  transform: translateY(3rem);
  transition: transform 0.6s, opacity 0.9s;

  &--revealed {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
  }
}

.swiper-wrapper.reveal-item {
  .swiper-slide-duplicate {
    h2 {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
    }
    .cta {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
    }
  }
}

.overflow {
  overflow: hidden;
}

.invert {
  fill: #FFFFFF;
}

@keyframes outlineColor {
  0%,100% {
    outline-color: rgba(0, 0, 0, 0.4);
  }

  50% {
    outline-color: rgba(0, 0, 0, 1);
  }
}
@keyframes outlineColorInverted {
  0%,100% {
    outline-color: rgba(255, 255, 255, 0.4);
  }

  50% {
    outline-color: rgba(255, 255, 255, 1);
  }
}
@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

:focus {
  outline: 2px solid transparent;
  animation: outlineColor 4s infinite;
}

body.using-mouse :focus {
  outline: none;
  animation: none;
}