.cb-standard {
  $cb: &;

  background-color: #9EBDAE;
  color: #000000;
  position: relative;

  * {
    box-sizing: border-box;
  }

  #{$cb}__container {
    width: min(1440px, 100%);
    padding: 80px 20px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      padding: 140px 40px;
    }

    @include media-breakpoint-up(lg) {
      padding: 140px 48px;
    }

    @include media-breakpoint-up(xl) {
      padding: 140px 48px;
    }

    @include media-breakpoint-down(xxs) {
      padding: 80px 15px;
    }
  
    @include media-breakpoint-down(xxxs) {
      padding: 80px 10px;
    }
  
    @include media-breakpoint-down(xxxxs) {
      padding: 80px 5px;
    }
    
    #{$cb}__LR {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: 0;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }

    #{$cb}__left,
    #{$cb}__right {
      display: flex;
      flex-direction: column;
      // align-items: center;
      width: 100%;
    }

    #{$cb}__sub-heading {
      font-family: "Prophet";
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -1px;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
      }

      @include media-breakpoint-down(xxs) {
        font-size: 20px;
        line-height: 28px;
      }
    
      @include media-breakpoint-down(xxxs) {
        font-size: 18px;
        line-height: 26px;
      }
    
      @include media-breakpoint-down(xxxxs) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    #{$cb}__left {
      #{$cb}__sub-heading {
        padding-bottom: 32px;
      }
    }

    #{$cb}__left,
    #{$cb}__right {
      width: 100%;
      margin-left: auto;
    }

    #{$cb}__left {
      @include media-breakpoint-up(md) {
        margin-left: 35px;
        padding-right: 35px;
      }

      @include media-breakpoint-up(lg) {
        // margin-left: 60px;
        width: min(456px, 90%);
      }
      
      @include media-breakpoint-up(xl) {
        margin-left: 40px;
      }

      @include media-breakpoint-up(xxl) {
        margin-left: 80px;
      }
    }
    
    #{$cb}__right {
      margin-right: 0;

      @include media-breakpoint-up(md) {
        margin-left: 0;
        padding-right: 0;
        padding-left: 35px;
        width: min(456px, 90%);
      }

      @include media-breakpoint-up(lg) {
        padding-left: 35px;
        margin-left: 0;
        margin-right: 20px;
      }
      
      @include media-breakpoint-up(xl) {
        margin-left: 80px;
      }

      @include media-breakpoint-up(xxl) {
        margin-right: 100px;
      }
  
      #{$cb}__sub-heading {
        padding-top: 60px;

        @include media-breakpoint-up(md) {
          padding-top: 0;
        }
      }

      .cta__container {
        max-width: 400px;
        width: 100%;

        .cta {
          margin-right: auto;
          &.dark {
            &::after {
              background-color: #9EBDAE;
            }
          }
        }
      }
    }

    #{$cb}__heading {
      @include clamp("font-size",32px,7vw,32px);
      line-height: 1.1;
      font-family: "Prophet";
      font-weight: normal;
      margin: 0;
      letter-spacing: -2px;
      padding-right: 20px;
      padding-bottom: 48px;
      
      @include media-breakpoint-up(md) {
        font-size: 40px;
        line-height: 48px;
      }

      @include media-breakpoint-down(xxs) {
        font-size: 28px;
      }
    
      @include media-breakpoint-down(xxxs) {
        font-size: 24px;
      }
    
      @include media-breakpoint-down(xxxxs) {
        font-size: 22px;
      }
    }

    #{$cb}__scrollbox {
      width: 300px;
      height: 240px;
      background-image: url("./images/scroll-box.png");
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 32px 32px 24px 24px;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      margin-bottom: 32px;

      @include media-breakpoint-up(md) {
        width: 300px;
        height: 240px;
      }
  
      @include media-breakpoint-up(lg) {
        width: 414px;
        height: 330px;
      }

      @include media-breakpoint-down(xxs) {
        width: 100%;
        background-size: 100% 100%;
        padding: 28px 28px 20px 20px;
      }
    
      @include media-breakpoint-down(xxxs) {
        width: 100%;
        background-size: 100% 100%;
        padding: 22px 22px 16px 12px;
      }
    
      @include media-breakpoint-down(xxxxs) {
        padding: 16px 16px 12px 8px;
      }

      #{$cb}__scrollbox-inner {
        overflow-y: scroll;
        height: 100%;
        scrollbar-color: black transparent;

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }
        &::-webkit-scrollbar-thumb {
          background: #363636;
          border: 0px none #ffffff;
          border-radius: 100px;
          transition: background-color .6s;
          cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #000000;
        }
        &::-webkit-scrollbar-thumb:active {
          background: #000000;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
          border: 0px none #ffffff;
          border-radius: 50px;
        }
        &::-webkit-scrollbar-track:hover {
          background: transparent;
        }
        &::-webkit-scrollbar-track:active {
          background: transparent;
        }
        &::-webkit-scrollbar-corner {
          background: transparent;
        }
      }

      #{$cb}__scrollbox-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 65px;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(243,240,236,1) 80%);
        margin: 0 40px 20px 20px;
        pointer-events: none;

        @include media-breakpoint-up(md) {
          height: 125px;
        }
      }

      &--job {
        font-family: "Prophet";
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: -0.24px;
        color: #000000;
        margin-bottom: 16px;
        display: block;
        text-decoration: none;
        margin-top: 3px;
        margin-left: 3px;
        margin-right: 3px;
        
        &:hover {
          > span:not(.cb-standard__scrollbox--job-title) {
            text-decoration: underline;
          }
        }

        @include media-breakpoint-up(md) {
          font-size: 18px;
        }
    
        @include media-breakpoint-up(lg) {
          font-size: 24px;
        }

        @include media-breakpoint-down(xxs) {
          font-size: 14px;
        }
      
        @include media-breakpoint-down(xxxs) {
          font-size: 12px;
          padding-right: 5px;
        }
      
        @include media-breakpoint-down(xxxxs) {
          font-size: 10px;
        }

        &-pos {
          display: block;
          
          .arrow {
            letter-spacing: 0;
            font-family: Prophet;
            transition: .15s;
          }
        }

        &-title {
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.16px;
          opacity: 0.7;
          display: inline-block;
          font-family: "dm_sansregular";

          @include media-breakpoint-up(md) {
            font-size: 14px;
          }
      
          @include media-breakpoint-up(lg) {
            font-size: 16px;
          }
        }
      }
    }

    #{$cb}__copy {
      width: 100%;
      max-width: 400px;
      // margin: 0 auto;
      
      p {
        font-size: 16px;
        line-height: 24px;
        font-family: "dm_sansregular";
        font-weight: normal;
        letter-spacing: -0.24px;
        padding-bottom: 32px;
        margin: 0;

        @include media-breakpoint-down(xxs) {
          font-size: 15px;
          line-height: 23px;
        }
      
        @include media-breakpoint-down(xxxs) {
          font-size: 14px;
          line-height: 22px;
        }
      
        @include media-breakpoint-down(xxxxs) {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    
    #{$cb}__sub-heading {
      padding-bottom: 16px;
    }

    #{$cb}__image {
      position: relative;
      width: 100%;
      height: 40vw;
      margin-top: 60px;

      @include media-breakpoint-up(md) {
        height: 20vw;
        max-height: 254px;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
    }
  }
}