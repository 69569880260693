.cb-columns {
  $cb: &;

  width: min(1440px, 100%);
  margin: 0 auto;
  padding: 80px 20px;

  @include media-breakpoint-up(md) {
    padding: 80px 40px;
  }

  @include media-breakpoint-up(lg) {
    padding: 80px 48px;
  }

  @include media-breakpoint-up(xl) {
    padding: 80px 48px;
  }

  @include media-breakpoint-down(xxs) {
    padding: 80px 15px;
  }

  @include media-breakpoint-down(xxxs) {
    padding: 80px 10px;
  }

  @include media-breakpoint-down(xxxxs) {
    padding: 80px 5px;
  }

  #{$cb}__heading-container {
    margin: 55px auto;
    opacity: 0;
    transform: translateY(3rem);
    transition: transform 0.6s, opacity 0.9s;
    transition-delay: 2s;

    &.animate {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }

    h2 {
      @include clamp("font-size",30px,9vw,40px);
      line-height: 1.1;
      max-width: 500px;
      font-family: Prophet;
      color: #000;
      font-weight: 400;
      padding: 0 0 12px 0;
      letter-spacing: -2px;
      text-align: left;

      @include media-breakpoint-down(xxs) {
        font-size: 28px;
      }
    
      @include media-breakpoint-down(xxxs) {
        font-size: 24px;
      }
    
      @include media-breakpoint-down(xxxxs) {
        font-size: 22px;
      }
    }
  }

  #{$cb}__col-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    #{$cb}__col {
      width: min(456px, 90%);
      margin-right: auto;

      @include media-breakpoint-up(md) {
        margin-left: 40px;
        padding-right: 35px;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 40px;
      }
      
      @include media-breakpoint-up(xl) {
        margin-left: 40px;
      }

      @include media-breakpoint-up(xxl) {
        margin-left: 80px;
      }

      &--image {
        width: 100%;
        position: relative;

        @include media-breakpoint-down(sm) {
          max-height: 400px;
        }

        &::before {
          content: "";
          display: block;
          padding-bottom: 150%;
        }

        @media (max-height: 500px) {
          width: 50%;
          max-width: 300px;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          z-index: 0;
          object-fit: cover;
        }
      }

      &--copy {
        width: 90%;

        h3 {
          font-size: 24px;
          line-height: 1.1;
          letter-spacing: -1px;
          color: #000;
          font-weight: 400;
          font-family: Prophet;
          text-align: left;
          padding-right: 15px;
          margin: 48px 0 32px;
        }

        p {
          font-family: dm_sansregular;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          margin: 16px 0;
          color: #666;
        }
      }

      &:last-child {
        margin-top: 50px;
        margin-left: auto;
        margin-right: 0;

        @include media-breakpoint-up(md) {
          margin-left: 0;
          margin-top: 35vh;
          padding-right: 0;
          padding-left: 35px;
        }

        @include media-breakpoint-up(lg) {
          margin-right: 20px;
          margin-top: 10vh;
        }

        @include media-breakpoint-up(xxl) {
          margin-right: 100px;
        }

        @include media-breakpoint-up(xxxl) {
          margin-top: 15vh;
        }

        @include media-breakpoint-up(xxxxl) {
          margin-top: 10vh;
        }

        #{$cb}__col--image {
          &::before {
            padding-bottom: 100%;
          }
        }
      }
    }
  }
}