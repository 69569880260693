.cta {
  $cta: &;

  font-family: dm_sansregular;
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  padding: 15px 20px 15px 26px;
  letter-spacing: -1px;
  margin-right: 15px;
  margin-bottom: 12px;
  width: 100%;
  max-width: 230px;

  @include media-breakpoint-up(md) {
    width: auto;
    max-width: none;
    margin-bottom: 0;
    padding: 12px 25px 12px 26px;
    border: 2px solid #000;
    font-size: 24px;
  }

  @include media-breakpoint-down(xxs) {
    padding: 10px 25px 10px 24px;
  }

  @include media-breakpoint-down(xxxs) {
    margin-right: 0;
    padding: 8px 20px 8px 20px;
  }

  @include media-breakpoint-down(xxxxs) {
    padding: 6px 20px 6px 8px;
  }

  &::after {
    @include media-breakpoint-up(md) {
      content: "";
      height: 25px;
      width: 25px;
      background-color: #fff;
      position: absolute;
      top: -13px;
      right: -13px;
      z-index: -1;
      border-bottom: 2px solid #000;
      transform: rotate(45deg) translate(0,0px);
      transition: all 0.15s ease-in;
      transform-origin: center;
    }
  }

  &:hover {
    &::after {
      width: 0;
      height: 0;
      top: 0;
      right: 0;      
    }

    .cta--arrow {
      right: 23px;
    }
  }

  &.light {
    color: #000;
    background-image: url(./images/cta-background-white-mob.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
      border: 2px solid #000;
      background-image: none;
    }
  }

  &.dark {
    color: #fff;
    background-image: url(./images/cta-background-black-mob.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    
    @include media-breakpoint-up(md) {
      background-color: #000;
      background-image: none;
    }
  }

  .cta--arrow {
    letter-spacing: 0;
    font-family: Prophet;
    float: right;
    padding-left: 15px;
    transition: .15s;
  }
}