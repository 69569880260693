.cb-carousel {
  $cb: &;

  background-color: #f4f0ec;

  #{$cb}__container {
    width: min(1440px, 100%);
    padding: 80px 0 80px 20px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      padding: 140px 40px;
    }

    @include media-breakpoint-up(lg) {
      padding: 140px 80px;
    }

    @include media-breakpoint-up(xl) {
      padding: 140px calc(48px + 80px);;
    }

    @include media-breakpoint-down(xxs) {
      padding: 80px 0 80px 15px;
    }
  
    @include media-breakpoint-down(xxxs) {
      padding: 80px 0 80px 10px;
    }
  
    @include media-breakpoint-down(xxxxs) {
      padding: 80px 0 80px 5px;
    }

    #{$cb}__heading {
      @include clamp("font-size",24px,7vw,40px);
      line-height: 1.1;
      font-family: Prophet;
      font-weight: 400;
      letter-spacing: -2px;
      margin-bottom: 40px;
    }

    #{$cb}__sub-heading {
      @include clamp("font-size",18px,7vw,24px);
      line-height: 1.1;
      font-family: dm_sansregular;
      font-weight: 400;
      position: absolute;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: flex-start;
          // max-width: 85%;
          padding-right: 10px;
          height: auto;
          padding-bottom: 140px;
          transition: opacity 0.4s;

          @include media-breakpoint-up(md) {
            max-width: none;
            width: 100%;
            padding-right: 0;
            padding-bottom: 0;
            align-items: center;
          }

          &:not(.swiper-slide-active) {
            opacity: 0.3;
          }

          .slide-row {
            padding: 40px 0 30px 0;
            max-width: 320px;
            position: relative;
            min-height: 160px;
            
            @include media-breakpoint-up(sm) {
              max-width: 420px;
              min-height: 160px;
            }

            @include media-breakpoint-up(md) {
              max-width: none;
              padding: 60px;
            }

            &::before {
              content: "You are a";
              @include clamp("font-size",14px, 4vw, 16px);
              line-height: 1.1;
              font-family: dm_sansregular;
              font-weight: 400;
              position: absolute;
              top: 20px;
              
              @include media-breakpoint-up(md) {
                display: none;
              }
            }

            &:nth-child(2) {
              padding: 40px 0 30px 0;

              @include media-breakpoint-up(md) {
                padding: 60px 60px 60px;
                margin-top: -0;
              }

              @include media-breakpoint-up(lg) {
                margin-top: -5px;
              }

              &::before {
                content: "Seeking a";
                
                @include media-breakpoint-up(md) {
                  display: none;
                }
              }
            }

            h2 {
              font-size: 35px;
              line-height: 1.2;
              letter-spacing: -2px;
              font-family: Prophet;
              font-weight: 400;
              margin: 0;

              @include media-breakpoint-up(md) {
                letter-spacing: -2px;
                @include clamp("font-size",30px,4.5vw,70px);
              }

              @include media-breakpoint-down(xxs) {
                font-size: 24px;
              }
            
              @include media-breakpoint-down(xxxs) {
                font-size: 22px;
              }
            
              @include media-breakpoint-down(xxxxs) {
                font-size: 16px;
                letter-spacing: 0;
              }
            }

            img {
              @include clamp("width",50px,13vw,90px);

              @include media-breakpoint-up(sm) {
                margin-top: 2px;
                @include clamp("width",50px,12vw,90px);
              }

              @include media-breakpoint-up(md) {
                margin-top: -5px;
              }

              @include media-breakpoint-down(xxs) {
                @include clamp("width",45px,15vw,90px);
              }
            
              @include media-breakpoint-down(xxxs) {
                @include clamp("width",40px,15vw,90px);
              }
            
              @include media-breakpoint-down(xxxxs) {
                @include clamp("width",40px,15vw,90px);
              }
            }
          }

          .cta {
            margin-top: 20px;
            margin-bottom: 60px;
            margin-right: auto;
            position: absolute;
            bottom: 0;

            @include media-breakpoint-up(md) {
              position: static;
              margin-top: 40px;
              margin-bottom: 0;
            }

            &:after {
              background-color: #f4f0ec;
            }
          }
        }
      }

      .swiper-button-container {
        @include media-breakpoint-down(sm) {
          display: none;
        }

        .swiper-button {
          font-family: Prophet;
          font-size: 88px;
          line-height: 96px;
          width: 100px;
          height: 60px;
          margin: 0;
          color: black;
          top: 40%;
          margin-top: 3px;
          
          
          &::after {
            display: none;
          }

          &:nth-child(1),
          &:nth-child(2) {
            top: 0;
          }

          &-next {
            transition-delay: 0s !important;
            &:hover {
              transform: translateX(10px);
            }
          }
          
          &-prev {
            transition-delay: 0s !important;
            &:hover {
              transform: translateX(-10px);
            }
          }
        }
      }

      .swiper-pagination {
        text-align: left;
        bottom: -5px;
        left: 0;
        transform: translateX(0);

        .swiper-pagination-bullet {
          opacity: 1;
          background: transparent;
          border: 1px solid black;
          width: 12px;
          height: 12px;
          
          &-active {
            background: black;
          }
          
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }
  }
}